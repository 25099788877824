<template>
  <div>
    <b-modal title="상품상세" size="xl" v-model="modal.detail">
      <template v-slot:modal-title>
        <b-badge class="ml-1" variant="warning">{{item.brand_nm}} ({{item.brand_nm_kr}})</b-badge>
        <b-badge class="ml-1" variant="light">{{item.cate}}</b-badge>
        <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.category)"><i class="fa fa-copy"></i></b-badge>
        <b-badge class="ml-1 pointer" variant="info" @click="copyText(item.goods_no)">{{item.goods_no}} <i class="fa fa-copy ml-1"></i></b-badge>
        <br/>
        <span v-if="item.goodsType === 'used'" class="text-danger">[빈티지{{item.usedGrade || '등급없음'}}]</span> {{item.goods_nm}}
        <div v-if="item.goods_nm_en">
          {{item.goods_nm_en}}
        </div>
      </template>

      <b-row>
        <b-col cols="12">
          <b-row class="mb-2">
            <b-col cols="5">
              <small>가격</small><br/>
              <div class="text-right pr-3">
                <div v-if="item.consumer !== item.price"><del>{{utils.comma(item.consumer)}}</del> 원</div>
                <div>{{utils.comma(item.price)}} 원</div>
                <div v-if="item.consumer !== item.price">({{100-Math.round(item.price/item.consumer*100)}} %)</div>
              </div>
              <a :href="`${MALL_URL}/shop/goods/goods_view.php?goodsno=${item.goods_no}`" target="_blank"
                 class="btn btn-sm btn-outline-dark">발란몰에서 상품보기 <i class="fa fa-external-link"></i></a><br/>
            </b-col>
            <b-col v-if="$R('SKU_INHOUSE')" cols="7">
              <div v-if="item.options && item.options.length">
                <b-badge variant="light">{{item.options[0].optnm}}</b-badge>
                <span class="badge badge-success">판매가능</span>
                <span class="badge badge-primary">SHOP재고</span>
                <span class="badge badge-info">결제수량</span>
                <span class="badge badge-warning">10분 미결제수량</span>
                <br/>
                <template v-for="e in item.options" v-if="!e.not_found">
                  <b-badge variant="light">{{e.Size}}</b-badge>
                  <span class="badge badge-success" title="판매 가능 재고수">{{e.stock}}</span>
                  <span class="badge badge-primary" title="SHOP 원본 재고수">{{e.shop_stock}}</span>
                  <span v-if="e.order_stock" class="badge badge-info" title="결제된 주문수량">{{e.order_stock}}</span>
                  <span v-if="e.prepared_stock" class="badge badge-warning" title="10분이내 주문 미결제수량">{{e.prepared_stock}}</span>&nbsp;
                  &nbsp;
                  <span v-if="e.goods_price !== item.price"><small>{{utils.comma(e.goods_price)}} 원 ({{utils.delta(e.goods_price - item.price, 2)}} 원)</small></span>
                  <span v-else class="text-muted"><small>(가격동일)</small></span>
                  <b-badge v-if="e.soldout" class="ml-1" variant="danger" title="품절이력이 있어서 재고가 되살아나지 않는 옵션입니다">품절</b-badge>
                  <br/>
                </template>
              </div>
            </b-col>
          </b-row>

          <div class="d-flex mb-3">
            <div class="flex-grow-1 w-100 cf_img">
              <small>이미지</small>
              <div>
                <template v-if="item.images">
                  <span v-for="(i, idx) in item.images">
                    <img :id="`imgs-${idx}`" :src="`https://i.balaan.io/${item.image_path}${i.thumb_200}`" style="height:100px"
                         @click="utils.open(`https://i.balaan.io/${item.image_path}${i.webp || i.jpg}`)"/>
                    <b-popover :target="`imgs-${idx}`" placement="bottom" triggers="hover focus">
                      <img :src="`https://i.balaan.io/${item.image_path}${i.thumb_300 || i.webp || i.org}`" style="max-width:300px"/>
                    </b-popover>
                  </span>
                </template>
                <template v-else>
                  <span v-for="(i, idx) in item.img_urls">
                    <img :id="`imgs-${idx}`" :src="i" style="height:100px" @click="utils.open(`${i}`)"/>
                    <b-popover :target="`imgs-${idx}`" placement="bottom" triggers="hover focus">
                      <img :src="i" style="max-width:250px"/>
                    </b-popover>
                  </span>
                </template>
              </div>
              <a :href="`/#/pages/image/confirmed/${item.goods_no}`" class="btn btn-primary btn-sm mt-1" target="_blank" v-if="item.image_path">이미지 보기</a>
            </div>
            <div v-if="epImageAsis" class="flex-grow-0">
              <small>EP 이미지<br/>(수동 크롭시)</small><br/>
              <img id="ep-image-asis" :src="epImageAsis" style="max-width:100px; border:1px solid #eee" @click="utils.open(epImageAsis)"/>
              <b-popover target="ep-image-asis" placement="bottom" triggers="hover focus">
                <img :src="epImageAsis" style="max-width:300px"/>
              </b-popover>
            </div>
            <div v-if="epImage" class="flex-grow-0">
              <small v-if="epImage.includes('_square')">현재 EP 이미지<br>(자동크롭)</small>
              <small v-else-if="epImage.includes('ep-image.balaan.io') && epImage.includes('?n=3')">현재 EP 이미지<br>(수동크롭)</small>
              <small v-else>현재 EP 이미지<br>(기본)</small>
              <br/>
              <img id="ep-image" :src="epImage" style="max-width:100px; border:1px solid #eee" @click="utils.open(epImage)"/>
              <b-popover target="ep-image" placement="bottom" triggers="hover focus">
                <img :src="epImage" style="max-width:300px"/>
              </b-popover>
            </div>
          </div>

          <b-row>
            <b-col :cols="$R('SKU_INHOUSE') ? 8 : 12">
              <div class="title-sm">상품코드</div>
              <small>SKU : </small>
              <template v-if="item.sku_id">
                <a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${(item.sku_id||'').replace(' ', '+')}`" target="_blank">
                  <b-badge variant="success">{{item.sku_id}} <i class="fa fa-external-link"></i></b-badge>
                </a>
                <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.sku_id)"><i class="fa fa-copy"></i></b-badge>
              </template><br/>
              <small>Short SKU : </small>
              <template v-if="item.short_sku">
                <a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${(item.short_sku || '').replace(' ', '+')}`" target="_blank">
                  <b-badge variant="success">{{item.short_sku}} <i class="fa fa-external-link"></i></b-badge>
                </a>
                <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.short_sku)"><i class="fa fa-copy"></i></b-badge>
              </template><br/>
              <small>통합 SKU : </small>
              <template v-if="item.matched_sku_id">
                <a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${(item.matched_sku_id||'').replace(' ', '+')}`" target="_blank">
                  <b-badge variant="success">{{item.matched_sku_id}} <i class="fa fa-external-link"></i></b-badge>
                </a>
                <b-badge class="ml-1 pointer" variant="light" @click="copyText(item.matched_sku_id)"><i class="fa fa-copy"></i></b-badge>
              </template>
            </b-col>
            <b-col v-if="$R('SKU_INHOUSE')" cols="4">
              <div class="title-sm">상품속성</div>
              <span :class="`badge alert-${item.delivery_type === '해외' ? 'success' : 'info'}`">
                {{ item.delivery_str }}
              </span>
              <span class="badge badge-light">{{ item.delivery_day }}일</span><br/>
              <template v-if="item.manual">
                <span class="badge alert-danger">파트너관리</span><br/>
              </template>
              <template v-if="item.oneday_delivery === 'Y'">
                <span class="badge alert-danger">오늘도착 가능</span><br/>
              </template>
              <template v-if="item.goodsType === 'used'">
                <span class="badge alert-warning">빈티지</span><span class="badge badge-light">{{ item.usedGrade }}</span><br/>
              </template>
            </b-col>
          </b-row>

          <b-row>
            <div class="title-sm w-100 mx-3">마스터 매칭 정보</div>
            <b-col cols="6">
              <small>마스터 ID : </small>
              <b-badge variant="success">{{item.gm_id || '마스터 없음'}}</b-badge>
              <br/>
              <small>매칭 상태 : </small>
              <b-badge variant="info">{{gmStatusKrMap[item.gm_status] || '매칭 대상'}}</b-badge>
              <br/>
              <small>마지막 매칭 상태 사유 : {{item.gm_status_reason || ''}}</small>
              <br/>
              <small>임시매칭 당시 라이브 여부 : {{item.gm_live_when_matched || ''}}</small>
            </b-col>
            <b-col cols="6">
              <small>임시 매칭자 : {{ item.gm_matcher_name || '' }}</small>
              <br/>
              <small>임시 매칭 시각 : {{ item.gm_matched_dt || '' }}</small>
              <br/>
              <small>확정 매칭자 : {{ item.gm_confirmer_name || '' }}</small>
              <br/>
              <small>확정 매칭 시각 : {{ item.gm_confirmed_dt || '' }}</small>
            </b-col>
          </b-row>

          <b-row class="mb-3" v-if="item.display_status === 'notview' && item.display_status_reason">
            <b-col cols="12">
              <span class="badge badge-warning">상품 미노출 사유</span><br/>
              <span v-html="item.display_status_reason"></span>
            </b-col>
          </b-row>

          <div class="title-sm">상품정보</div>
          <b-row class="mb-3">
            <b-col cols="6" lg="3">
              <small>컬러</small><br/>
              {{item.color || '-'}}
            </b-col>
            <b-col cols="6" lg="3">
              <small>컬러 자동매칭(major_color)</small><br/>
              <template v-if="item.major_color && colorMap[item.major_color]">
                <div class="d-inline-block" :style="{backgroundColor:'#'+colorMap[item.major_color].color, width:'22px', height:'22px'}"></div>
                <div class="d-inline-block ml-1" style="vertical-align: super">{{item.major_color}}</div>
              </template>
              <span v-else>-</span>
            </b-col>
            <b-col cols="6" lg="3">
              <small>시즌</small><br/>
              {{item.season || '-'}}
            </b-col>
            <b-col class="mt-sm-2 mt-lg-0" cols="6" lg="3">
              <small>원산지</small><br/>
              {{item.origin || '-'}}
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="12">
              <small>소재</small><br/>
              <span v-html="item.composition || '-'"></span>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="12">
              <small>measurements</small><br/>
              <span v-html="item.measurements || '-'"></span>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col class="cf_desc" cols="12">
              <small>설명 (본문의 이미지는 편의상 300px 로 제한됨, 실제로는 꽉차게 보임)</small><br/>
              <div ref="desc" class="mh-600" v-html="item.description || '-'"></div>
              <b-button block variant="outline-light" v-if="$refs.desc && $refs.desc.offsetHeight === 600"
                        @click="e => { e.target.previousSibling.classList.remove('mh-600'); e.target.classList.add('d-none')}">Show More...</b-button>
            </b-col>
          </b-row>

          <h5 v-if="$R('GOODS_R') && item.mapped && item.mapped.src_not_found">
            <small class="text-muted"><i class="fa fa-exclamation-circle"></i> {{item.mapped.not_found._dt}} 이후로 원본정보에서 찾을 수 없습니다</small>
          </h5>
          <div class="clearfix">
            <b-button v-if="$R('GOODS_R') && item.mapped" class="mr-1 mb-1" size="sm" variant="light" v-b-toggle.mapped>원본정보 확인</b-button>
          </div>

          <b-collapse id="mapped" v-if="$R('GOODS_R') && item.mapped">
            <hr/>
            <div class="clearfix">
              <b-button class="float-right" v-if="item.mapped._diff && item.mapped._diff.length" variant="outline-light" @click="showDiffModal(item.mapped)">
                수정이력
              </b-button>
              <h5 v-if="item.mapped.src_not_found">
                Mapped
                <small class="text-muted"><i class="fa fa-exclamation-circle"></i> {{item.mapped.not_found._dt}} 이후로 원본정보에서 찾을 수 없습니다</small>
              </h5>
              <h5 v-else>Mapped</h5>
            </div>
            <div class="mb-3">
              <b-badge class="ml-1" variant="warning">{{item.mapped.brand_name}}</b-badge>
              <b-badge class="ml-1" variant="secondary">{{item.mapped.category}}</b-badge>
              <b-badge class="ml-1" variant="light">{{item.mapped.origin_category}}</b-badge><br/>
              {{item.mapped.name}}
            </div>
            <div class="mb-4 mp_img">
              <template v-if="item.mapped.imageReady">
                <small>일반 이미지 ({{item.mapped.images.length}} 개)</small><br/>
                <div class="mt-2">
                  <span v-for="(i, idx) in item.mapped.images">
                    <img :id="`mapped_images-${idx}`" :src="`https://i.balaan.io/${item.mapped.image_path}${i.thumb_200}`" style="height:100px"
                         @click="utils.open(`https://i.balaan.io/${item.mapped.image_path}${i.webp || i.jpg}`)"/>
                    <b-popover :target="`mapped_images-${idx}`" placement="bottom" triggers="hover focus">
                      <img :src="`https://i.balaan.io/${item.mapped.image_path}${i.thumb_300 || i.org}`" style="max-width:300px"/>
                    </b-popover>
                  </span>
                </div>
                <div class="mt-3" v-if="item.mapped.longImages">
                  <small>긴 이미지로 분류된 이미지 ({{item.mapped.longImages.length}} 개)</small><br/>
                  <div class="mt-2">
                    <span v-for="(i, idx) in item.mapped.longImages">
                      <div style="width: 100px; max-height: 100px; overflow: hidden">
                        <img :id="`mapped_longImages-${idx}`" :src="`https://i.balaan.io/${item.mapped.image_path}${i.org_webp || i.org}`" style="width: 100%"
                             @click="utils.open(`https://i.balaan.io/${item.mapped.image_path}${i.org_webp || i.org}`)"/>
                      </div>
                      <b-popover :target="`mapped_longImages-${idx}`" placement="bottom" triggers="hover focus">
                        <img :src="`https://i.balaan.io/${item.mapped.image_path}${i.thumb_300 || i.org}`" style="max-width:300px"/>
                      </b-popover>
                    </span>
                  </div>
                </div>
                <a :href="`/#/pages/image/mapped/${encodeURIComponent(item.mapped.gid)}`" class="btn btn-primary btn-sm mt-1" target="_blank" v-if="item.image_path">이미지 보기</a>
              </template>
              <template v-else>
                <small>이미지</small><br/>
                <div v-if="item.shop_id != 46" class="mt-2">
                  <span v-for="(i, idx) in item.img_urls">
                    <img :id="`mapped_imgs-${idx}`" :src="i" style="height:100px" @click="utils.open(`${i}`)"/>
                    <b-popover :target="`mapped_imgs-${idx}`" placement="bottom" triggers="hover focus">
                      <img :src="i" style="max-width:300px"/>
                    </b-popover>
                  </span>
                </div>
                <div v-else>
                  46. OKMALL 은 이미지를 가져오지 않습니다
                </div>
              </template>
            </div>
            <b-row class="mb-3">
              <b-col cols="6" lg="3">
                <small>가격</small><br/>
                <div class="text-right pr-3">
                  <div v-if="item.mapped.discount_rate > 0"><del>{{utils.rnc(item.mapped.price / (100-item.mapped.discount_rate) * 100, 2)}}</del> {{item.mapped.price_unit}}</div>
                  <div>{{utils.rnc(item.mapped.price, 2)}} {{item.mapped.price_unit}}</div>
                  <div v-if="item.mapped.discount_rate > 0">({{item.mapped.discount_rate}} %)</div>
                </div>
              </b-col>
              <b-col cols="6" lg="4">
                <div v-if="item.mapped.options && item.mapped.options.length">
                  <small>{{item.mapped.options[0].optnm}}</small><br/>
                  <template v-for="e in item.mapped.options">
                    <b-badge variant="light">{{e.Size}}</b-badge><b-badge variant="success">{{e.stock}}</b-badge>
                    &nbsp;
                    <span v-if="e.goods_price !== item.mapped.price"><small>{{utils.rnc(e.goods_price, 2)}} {{item.mapped.price_unit}} ({{utils.delta(e.goods_price - item.mapped.price, 2)}} {{item.mapped.price_unit}})</small></span>
                    <span v-else class="text-muted"><small>(가격동일)</small></span>
                    <br/>
                  </template>
                </div>
              </b-col>
              <b-col cols="6" lg="5">
                <small>상품코드</small><br/>
                <small>SKU : </small><a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${(item.mapped.sku_id||'').replace(' ', '+')}`" target="_blank"><b-badge variant="success">{{item.mapped.sku_id}}</b-badge></a><br/>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="3">
                <small>컬러</small><br/>
                {{item.mapped.color || '-'}}
              </b-col>
              <b-col cols="3">
                <small>시즌</small><br/>
                {{item.mapped.launch_date || '-'}}
              </b-col>
              <b-col cols="3">
                <small>원산지</small><br/>
                {{item.mapped.origin || '-'}}
              </b-col>
              <b-col cols="3">
                <small>성별</small><br/>
                {{item.mapped.generation || '-'}}
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="3">
                <small>배송유형</small><br/>
                {{item.mapped.delivery_type || '-'}}
              </b-col>
              <b-col cols="9">
                <small>소재</small><br/>
                <span v-html="item.mapped.composition || '-'"></span>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12">
                <small>measurements</small><br/>
                <span v-html="item.mapped.measurements || '-'"></span>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12">
                <small>modelsize</small><br/>
                <span v-html="item.mapped.modelsize || '-'"></span>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col class="mp_desc" cols="12">
                <small>설명 <span v-if="item.mapped.longImages">(상품이 등록될 때 긴 이미지가 포함되게 됨)</span></small><br/>
                <div ref="mp_desc" class="mh-600" v-html="item.mapped.description || '-'"></div>
                <b-button block variant="outline-light" v-if="$refs.mp_desc && $refs.mp_desc.offsetHeight === 600"
                          @click="e => { e.target.previousSibling.classList.remove('mh-600'); e.target.classList.add('d-none')}">Show More...</b-button>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="6" lg="3">
                <small>최초등록일</small><br/>
                {{item.mapped._cdt}}
              </b-col>
              <b-col cols="6" lg="3">
                <small>최근확인시각</small><br/>
                {{item.mapped._dt}}
              </b-col>
            </b-row>
          </b-collapse>
        </b-col>
      </b-row>
      <template v-slot:modal-footer="{ ok }">
        <b-button v-if="$R(['GOODS_X', 'DEV'])" variant="outline-light" @click="jsonModal('JSON 보기', item)">
          JSON
        </b-button>
        <b-button v-if="item._diff && item._diff.length" variant="outline-light" @click="showDiffModal(item)">
          수정이력
        </b-button>
        <b-button variant="primary" @click="ok()">
          닫기
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import * as utils from '@/shared/utils'
import {Types} from "mongoose";

export default {
  name: "ConfirmedModal",
  props: ['modal', 'shopMap', 'targetMap', 'collection', 'gmStatusKrMap'],
  data() {
    return {
      utils,
      colorMap: this.$utils.arr2map(this.$C.COLORS, 'name'),
      item: {},
      itemAttr: {},
      epImageAsis: null,
      epImage: null
    };
  },
  computed:{
  },
  created() {
    // colorMap gray 보정
    this.colorMap.gray = this.colorMap.grey;
  },
  methods: {
    showModal(row) {
      let item = this.item = row.item;
      // 상품에 따라 변경될 수 있도록 - 수정필요
      if (!item || !item.goods_no) return this.modal.detail = false;

      this.itemAttr = {};

      // _diff 가 있다면 최근 가격변경, 재고변경일을 가져온다
      if (item._diff && item._diff.length) {
        item.stock_diff = item._diff.filter(e=>e.tot_stock).reverse()[0];
        item.price_diff = item._diff.filter(e=>e.price).reverse()[0];
      }
      this.epImage = null;
      this.epImageAsis = null;
      this.$api.getJson('/goods/epImage?goods_no='+item.goods_no).then(j=>{
        if (!j) return;
        this.epImage = j.url;
        if (j.url.includes('_square')) {
          this.epImageAsis = j.url.replace('i.balaan.io', 'ep-image.balaan.io').replace('_square', '') + '?n=3';
        } else if (j.url.includes('ep-image.balaan.io')) {
          this.epImageAsis = j.url.replace('?n=2', '?n=3');
        }
      });

      if (this.$R('GOODS_R')) {
        this.$api.postJson('/goods/mapped/one', {form:{shop_id:item.shop_id, goods_id:item.goods_id}}).then(j=>{
          if (!j || !j.one) return;
          this.item.mapped = j.one;
          this.item.mapped._cdt = utils.kstDT(Types.ObjectId(this.item.mapped._id).getTimestamp());
          if (this.item.mapped._diff) {
            this.item.mapped.not_found = this.item.mapped._diff.filter(e => e.src_not_found || e._desc === 'src_not_found: true').reverse()[0];
          }
          // setTimeout(() => {console.log(this.item.mapped); this.$forceUpdate()}, 1000);
          // this.$nextTick(() => this.$forceUpdate());
          // setImmediate(() => this.$forceUpdate());
        });
      }

      this.modal.detail = true;
    },
    jsonModal(title, json) {
      this.$modal.show({title, type:'json', item:json});
    },
    showDiffModal(item){
      this.$modal.show({title: '수정이력 확인', type: 'diff', item: item, db: 'hub', collection: 'confirmed'});
    },
    copyText(e) {
      let res = this.$utils.copyToClipboard(e);
      if (res) this.$alertTop(`"${e}" 로 복사되었습니다`);
    },
  }
}
</script>
